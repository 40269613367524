<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-margin uk-width-1-4">
            <button class="uk-button uk-button-default uk-width-expand" style="background:white" type="button">{{ filterDate() }}</button>
            <div id="date-dropdown" uk-dropdown="mode: click;pos: right-top">
                <ul class="uk-dropdown-grid uk-child-width-auto" uk-grid>
                    <div>
                        <datetime
                                id="start_date"
                                input-class="uk-button"
                                v-model="meta.start_date"
                                :max-datetime="new Date().toISOString()"
                                placeholder="Start Date"
                                @select="getInitPageData()"
                        ></datetime>
                    </div>
                    <template v-if="meta.start_date">
                        <div class="uk-text-center uk-margin-auto-vertical">-</div>
                        <div>
                            <datetime
                                    id="end_date"
                                    input-class="uk-button"
                                    v-model="meta.end_date"
                                    :min-datetime="meta.start_date"
                                    :max-datetime="new Date().toISOString()"
                                    placeholder="End Date"
                            ></datetime>
                        </div>
                        <div>
                            <button class="uk-button uk-button-primary uk-width-auto" type="button" v-if="meta.start_date" @click="setDate()">Apply</button>
                        </div>
                    </template>
                </ul>
            </div>
            <button class="uk-button-small uk-button-danger uk-width-auto" type="button" v-if="meta.start_date" @click="resetDate()"><span uk-icon="icon: close"></span></button>
        </div>
        <div class="uk-flex  uk-flex-center">
            <div class="uk-width-auto uk-margin-right">
                <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded uk-text-center">
                    <div class="uk-width-1-1 uk-text-center">
                        <h3>User yang mengisi profil 100%</h3>
                    </div>
                    <div class="uk-width-1-1 uk-flex uk-flex-center">
                        <div class="uk-width-auto">
                            <div class="uk-width-1-1">
                                <h1 class="uk-text-bold">{{formatCurrency(page_data.count_profile_complete)}}</h1>
                            </div>
                            <div class="uk-width-1-1">
                                <h4>User</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-width-auto">
                <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded uk-text-center">
                    <div class="uk-width-1-1 uk-text-center">
                        <h3>Jumlah User PWA Baru</h3>
                    </div>
                    <div class="uk-width-1-1 uk-flex uk-flex-center">
                        <div class="uk-width-auto">
                            <div class="uk-width-1-1">
                                <h1 class="uk-text-bold">{{formatCurrency(page_data.count_download_cv)}}</h1>
                            </div>
                            <div class="uk-width-1-1">
                                <h4>User</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-flex uk-flex-center uk-margin-top">
            <div class="uk-width-auto uk-margin-right">
                <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded uk-text-center">
                    <div class="uk-width-1-1 uk-text-center">
                        <h3>Jumlah kandidat & Mitra Berdasarkan Pendidikan</h3>
                    </div>
                    <div class="uk-width-1-1 uk-flex uk-flex-center">
                        <div class="uk-width-1-1 uk-text-center uk-margin-large-right">
                            <h4>Kandidat</h4>
                            <div v-for="(list, index) in page_data.count_candidate_by_edu" :key="index" class="uk-flex uk-flex-center">
                                <div class="uk-width-1-1 uk-text-left">
                                    <h5>{{ list.name }}</h5>
                                </div>
                                <div class="uk-width-1-1 uk-text-right">
                                    <h5>{{ list.count }}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-text-center">
                            <h4>Mitra</h4>
                            <div v-for="(list, index) in page_data.count_partner_by_edu" :key="index" class="uk-flex uk-flex-center">
                                <div class="uk-width-1-1 uk-text-left">
                                    <h5>{{ list.name }}</h5>
                                </div>
                                <div class="uk-width-1-1 uk-text-right">
                                    <h5>{{ list.count }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-width-auto">
                <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded uk-text-center">
                    <div class="uk-width-1-1 uk-text-center">
                        <h3>Jumlah Mitra Berdasarkan Pekerjaan</h3>
                    </div>
                    <div class="uk-width-1-1 uk-flex uk-flex-center">
                        <div class="uk-width-auto uk-text-center">
                            <h4>Mitra</h4>
                            <div v-for="(list, index) in page_data.count_partner_by_job_type" :key="index" class="uk-flex uk-flex-center">
                                <div class="uk-width-1-1 uk-text-left uk-margin-large-right">
                                    <h5>{{ list.name }}</h5>
                                </div>
                                <div class="uk-width-1-1 uk-text-right">
                                    <h5>{{ list.count }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-flex uk-flex-center uk-margin-top">
            <div class="uk-width-auto uk-margin-right">
                <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded uk-text-center">
                    <div class="uk-width-1-1 uk-text-center">
                        <h3>Jumlah kandidat & Mitra Berdasarkan Jenis Kelamin</h3>
                    </div>
                    <div class="uk-width-1-1 uk-flex uk-flex-center">
                        <div class="uk-width-1-1 uk-text-center uk-margin-large-right">
                            <h4>Kandidat</h4>
                            <div v-for="(list, index) in page_data.count_candidate_by_gender" :key="index" class="uk-flex uk-flex-center">
                                <div class="uk-width-1-1 uk-text-left">
                                    <h5>{{ list.name }}</h5>
                                </div>
                                <div class="uk-width-1-1 uk-text-right">
                                    <h5>{{ list.count }}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-text-center">
                            <h4>Mitra</h4>
                            <div v-for="(list, index) in page_data.count_partner_by_gender" :key="index" class="uk-flex uk-flex-center">
                                <div class="uk-width-1-1 uk-text-left">
                                    <h5>{{ list.name }}</h5>
                                </div>
                                <div class="uk-width-1-1 uk-text-right">
                                    <h5>{{ list.count }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="uk-width-auto">
                <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded uk-text-center">
                    <div class="uk-width-1-1 uk-text-center">
                        <h3>Jumlah User yang Mengerjakan Assessment Test</h3>
                    </div>
                    <div class="uk-flex uk-flex-center">
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <p class="uk-text-bold">Nama Test</p>
                        </div>
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <p class="uk-text-bold">To Do</p>
                        </div>
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <p class="uk-text-bold">Did It</p>
                        </div>
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <p class="uk-text-bold">Persentase</p>
                        </div>
                    </div>
                    <div v-for="(list, index) in page_data.count_user_by_take_assessment" :key="index" class="uk-flex uk-flex-center">
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <p>{{ list.name }}</p>
                        </div>
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <p>{{ list.count }}</p>
                        </div>
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <p>{{ list.count_success }}</p>
                        </div>
                        <div class="uk-flex uk-flex-column uk-width-1-2">
                            <p>{{ list.count_percentage.toFixed() }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="uk-flex uk-flex-center uk-margin-top">
            <div class="uk-width-auto">
                <div class="uk-card-small uk-card-default uk-card-body uk-width-expand uk-margin-auto uk-border-rounded uk-text-center">
                    <div class="uk-width-1-1 uk-text-center">
                        <h3>Jumlah Kandidat & Mitra Berdasarkan Usia</h3>
                    </div>
                    <div class="uk-width-1-1 uk-flex uk-flex-center">
                        <div class="uk-width-1-1 uk-text-center uk-margin-large-right">
                            <h4>Kandidat</h4>
                            <div v-for="(list, index) in page_data.count_candidate_by_age" :key="index" class="uk-flex uk-flex-center">
                                <div class="uk-width-1-1 uk-text-left">
                                    <h5>{{ list.age }} Tahun</h5>
                                </div>
                                <div class="uk-width-1-1 uk-text-right">
                                    <h5>{{ list.count }}</h5>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-text-center">
                            <h4>Mitra</h4>
                            <div v-for="(list, index) in page_data.count_partner_by_age" :key="index" class="uk-flex uk-flex-center">
                                <div class="uk-width-1-1 uk-text-left">
                                    <h5>{{ list.age }} Tahun</h5>
                                </div>
                                <div class="uk-width-1-1 uk-text-right">
                                    <h5>{{ list.count }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { Datetime } from 'vue-datetime';
import formatter from "@/utils/formatter";

moment.locale('id');

export default {
    data() {
        return {
            meta: {
                start_date: null,
                end_date: null,
                limit: 50,
                page: 1
            },
            page_data: {docs: [], totalDocs: 0},
            isLoading: true
        };
    },
    components: {
        Datetime
    },
    async mounted() {
        await this.getInitPageData();
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    methods: {
        ...mapActions({
            getCandidateReport: 'report/getCandidateReport',
        }),
        async getInitPageData() {
            this.isLoading = true;
            this.page_data = await this.getCandidateReport(this.meta);
            this.isLoading = false;
        },
        async setDate() {
            await this.getInitPageData();
            await window.UIkit.dropdown('#date-dropdown').hide();
        },
        async resetDate() {
            this.meta.start_date = null;
            this.meta.end_date = null;
            await this.getInitPageData();
        },
        filterDate() {
            if (!this.meta.start_date) {
                return 'Filter Tanggal';
            } else if (!this.meta.end_date) {
                return formatter.dateComplete(this.meta.start_date);
            } else {
                return formatter.dateComplete(this.meta.start_date) + ' - ' + formatter.dateComplete(this.meta.end_date);
            }
        },
        formatCurrency(data) {
            if (data == null) {
                return '-';
            }
            return formatter.thousandSeparator(data);
        },
    },
};
</script>
